import React, { useState } from "react";
import { Link } from "react-scroll";

const navigationLinks = [
  {
    name: "Projects",
    to: "projects",
  },
  {
    name: "Graphics",
    to: "graphics",
  },
  {
    name: "Contact",
    to: "contact",
  },
];

const Navbar = () => {
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);

  const toggleMobileNav = () => {
    setIsMobileNavOpen(!isMobileNavOpen);
  };

  return (
    <div className="navigation">
      <nav className="navigation__bar">
        <div className="navigation__desktop">
          <div className="navigation__logo">
            <div className="navigation__logo home">
              <img src="" alt="Walid Boubguira Portfolio" />
            </div>
          </div>
          <ul className="navigation__list">
            {navigationLinks.map((link, i) => (
              <li className="navigation__item" key={i}>
                <Link
                  className="navigation__link"
                  to={link.to}
                  smooth
                  duration={500}
                  href={`#${link.to}`}
                >
                  {link.name}
                </Link>
              </li>
            ))}
          </ul>
        </div>

        <div className="navigation__mobile">
          <div className="navigation__logo">
            <div className="navigation__logo home">
              <img src="" alt="Walid Boubguira Portfolio" />
            </div>
          </div>

          <button
            className="navigation__hamburger"
            tabIndex={0}
            aria-label="Toggle  mobile navigation"
            onClick={toggleMobileNav}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 100 100"
              className={
                isMobileNavOpen
                  ? "navigation__toggleOpen"
                  : "navigation__toggle"
              }
            >
              <g
                fill="none"
                fillRule="evenodd"
                strokeLinecap="square"
                strokeWidth="10"
              >
                <line x1="9" y1="22" x2="91" y2="22" id="top" />
                <line x1="9" y1="50" x2="91" y2="50" id="middle" />
                <line x1="9" y1="78" x2="91" y2="78" id="bottom" />
              </g>
            </svg>
          </button>

          {isMobileNavOpen && (
            <div className="navigation__mobileNav">
              <ul className="navigation__mobileList">
                {navigationLinks.map((link, i) => (
                  <li className="navigation__mobileItem" key={i}>
                    <Link
                      className="navigation__link"
                      to={link.to}
                      smooth
                      duration={500}
                      onClick={toggleMobileNav}
                      href={`#${link.to}`}
                    >
                      {link.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
