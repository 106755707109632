import PropTypes from "prop-types";
import React from "react";
import Button from "../components/Button/Button";

const Card = ({
  title,
  description,
  technologies,
  gitHub,
  appLink,
  image,
  responsibilities,
}) => {
  return (
    <div className="card">
      <div className="front">
        <img src={image} alt={title} title={title} />
        <h3>{title}</h3>
        <p>{description}</p>

        <ul className="responsibilities">
          {responsibilities?.map((responsibility, i) => {
            return <li key={i}>{responsibility}</li>;
          })}
        </ul>
      </div>

      <div className="back">
        <h4>Techs</h4>
        <p>{technologies}</p>

        <div className="cta">
          {gitHub && (
            <Button href={gitHub} text={"</>"} tag={"a"} title={"GitHub"} />
          )}
          {appLink && (
            <Button
              href={appLink}
              text={"Live"}
              tag={"a"}
              title={"Live Site"}
            />
          )}
        </div>
      </div>
    </div>
  );
};

Card.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  technologies: PropTypes.string.isRequired,
  gitHub: PropTypes.string,
  appLink: PropTypes.string,
  image: PropTypes.string.isRequired,
  responsibilities: PropTypes.array,
};

export default Card;
