import React from "react";
import PropTypes from "prop-types";

const Button = ({ text, className, tag, href, type, title }) => {
  const Tag = tag;

  return (
    <Tag
      className={`button ${className}`}
      href={href}
      type={type}
      target="_blank"
      rel="noopener noreferrer"
      title={title}
    >
      <span className="button__text">{text}</span>
      <div className="button__horizontal"></div>
      <div className="button__vertical"></div>
    </Tag>
  );
};

Button.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
  tag: PropTypes.string.isRequired,
  href: PropTypes.string,
  type: PropTypes.string,
  title: PropTypes.string,
};

export default Button;
