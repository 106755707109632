import React from "react";
import TypeWriter from "../../helpers/TypeWriter";
import { useState, useEffect } from "react";
import Button from "../Button/Button";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import { useCallback } from "react";
import useDarkMode from "use-dark-mode";
import About from "../About/About";

const msgs = [
  "💡 I am an Innovator.",
  "📚 A Lifelong Learner.",
  "💻 A Web Developer.",
  "⚙️ A Software Engineer.",
  "🎨 A UI/UX Designer.",
  "🎵 A Music Enthusiast.",
  "🎮 A Gamer.",
  "🎥 A Movie Buff.",
  "📖 A Bookworm.",
];

const Hero = () => {
  const [scrollPostionIni, setScrollPositionIni] = useState(0);
  const [particleBg, setParticleBg] = useState("");
  const [lineColor, setLineColor] = useState("");
  const [connectColor, setConnectColor] = useState("");
  const darkMode = useDarkMode(false);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const isClient = typeof window !== "undefined";

  useEffect(() => {
    if (isClient && darkMode.value) {
      setIsDarkMode(darkMode.value);
    } else {
      setIsDarkMode(false);
    }
  }, [isClient, darkMode.value]);

  useEffect(() => {
    if (isDarkMode) {
      setParticleBg("#181818");
      setLineColor("#ffffff");
      setConnectColor("#ffffff");
    } else {
      setParticleBg("#ffffff");
      setLineColor("#99abaf");
      setConnectColor("#99abaf");
    }
  }, [isDarkMode]);

  useEffect(() => {
    if (isClient) {
      const handleScroll = () => {
        setScrollPositionIni(window.scrollY);
      };
      window.addEventListener("scroll", handleScroll);
      return () => window.removeEventListener("scroll", handleScroll);
    }
  }, [isClient]);

  const particlesInit = useCallback(async (engine) => {
    await loadFull(engine);

    return false;
  }, []);

  const particlesLoaded = useCallback(async (container) => {
    return false;
  }, []);

  return (
    <section>
      <div className="hero">
        <Particles
          id="tsparticles"
          init={particlesInit}
          loaded={particlesLoaded}
          options={{
            background: {
              color: {
                value: particleBg,
              },
            },
            fpsLimit: 180,
            interactivity: {
              events: {
                // onClick: {
                //   enable: true,
                //   mode: "push",
                // },
                onHover: {
                  enable: true,
                  mode: "repulse",
                },
                resize: true,
              },
              modes: {
                push: {
                  quantity: 4,
                },
                repulse: {
                  distance: 200,
                  duration: 0.4,
                },
              },
            },
            particles: {
              color: {
                value: connectColor,
              },
              links: {
                color: lineColor,
                distance: 150,
                enable: true,
                opacity: 0.5,
                width: 1,
              },
              collisions: {
                enable: true,
              },
              move: {
                directions: "none",
                enable: true,
                outModes: {
                  default: "bounce",
                },
                random: false,
                speed: 2,
                straight: false,
              },
              number: {
                density: {
                  enable: true,
                  area: 3000,
                },
                value: 80,
              },
              opacity: {
                value: 0.5,
              },
              shape: {
                type: "circle",
              },
              size: {
                value: { min: 1, max: 5 },
              },
            },
            detectRetina: true,
          }}
        />
        <div className="hero__info ">
          <h1 className="hero__name">
            <TypeWriter heading={"Hi, I'm Walid "} messages={msgs} />
          </h1>
          <div className="hero__resumeBtn">
            <Button tag="a" text="Download Resume" href="/resume.pdf" />
          </div>
        </div>

        <About />

        <div
          style={{
            opacity: 350 > scrollPostionIni > 0 ? 1 : 0,
          }}
          className="jsx-123"
        >
          <p className="hero__scrollDown">Scroll down</p>
        </div>
      </div>
    </section>
  );
};

export default Hero;
