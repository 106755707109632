import React from "react";
import { AiOutlineDatabase } from "react-icons/ai";
import { BsFullscreenExit } from "react-icons/bs";
import { DiIllustrator } from "react-icons/di";
import { FaConnectdevelop } from "react-icons/fa";
import { GiGrapple } from "react-icons/gi";
import { MdDeveloperMode } from "react-icons/md";
import { SiSimpleicons } from "react-icons/si";
import Modal from "../../helpers/Modal";
import useFormFields from "../../helpers/useFormFields";
import useModal from "../../helpers/useModal";
import useDarkMode from "use-dark-mode";

import emailjs from "emailjs-com";
import Button from "../Button/Button";

const initialState = {
  name: "",
  email: "",
  message: "",
};

const services = [
  {
    name: "Front-end Development",
    color: "#f7f7f7",
    backgroundColor: "#f7f7f7",
    icon: <MdDeveloperMode />,
  },
  {
    name: "Back-end Development",
    color: "#f7f7f7",
    backgroundColor: "#f7f7f7",
    icon: <AiOutlineDatabase />,
  },
  {
    name: "Web Design",
    color: "#f7f7f7",
    backgroundColor: "#f7f7f7",
    icon: <BsFullscreenExit />,
  },
  {
    name: "Graphic Design",
    color: "#f7f7f7",
    backgroundColor: "#f7f7f7",
    icon: <DiIllustrator />,
  },
  {
    name: "Consulting",
    color: "#f7f7f7",
    backgroundColor: "#f7f7f7",
    icon: <SiSimpleicons />,
  },
  {
    name: "Product Engineering",
    color: "#f7f7f7",
    backgroundColor: "#f7f7f7",
    icon: <FaConnectdevelop />,
  },
  {
    name: "UI/UX Design",
    color: "#f7f7f7",
    backgroundColor: "#f7f7f7",
    icon: <GiGrapple />,
  },
];

const Contact = () => {
  const { fields, setFields, resetFields } = useFormFields(initialState);
  const { isShowing, toggle } = useModal();
  const darkMode = useDarkMode();
  const submitForm = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "contact_form",
        "portfolio_email",
        e.target,
        "user_3kTZWLWPDMYKracgq5h6D"
      )
      .then(
        (result) => {
          // TODO: Reconnect to emailjs
          // console.log(result.text);
        },
        (error) => {
          // console.log(error.text);
        }
      );

    toggle();
    resetFields();
  };

  const { name, email, message } = fields;

  return (
    <section className="contact">
      <div
        style={{
          backgroundImage: `url(${
            darkMode.value ? "./stroke-dark.png" : "./stroke-light.png"
          })`,
          backgroundPosition: "left",
          backgroundRepeat: "no-repeat",
          minHeight: "130px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <h2 className="contact__title">Contact</h2>
      </div>
      <div className="contact__header">
        <p className="contact__subtitle">
          Tell me about your project, I'd love to hear about it. I'm currently
          looking for new opportunities, my inbox is always open. Whether you
          have a question or just want to say hi, I'll try my best to get back
          to you!
        </p>
      </div>

      <div className="contact__bg"></div>
      <div className="contact__wrapper">
        <form onSubmit={submitForm} className="contact__form">
          <div className="contact__form--input">
            <input
              id="name"
              aria-label="name"
              type="text"
              className="contact__form--input-field"
              placeholder="Name"
              value={name}
              name="name"
              onChange={setFields}
              required
            />
          </div>
          <div className="contact__form--input">
            <input
              id="email"
              aria-label="email"
              type="email"
              placeholder="Email"
              value={email}
              name="email"
              onChange={setFields}
              required
            />
          </div>
          <div className="contact__form--input">
            <textarea
              id="message"
              autoComplete="off"
              aria-label="message"
              rows="5"
              placeholder="Message"
              value={message}
              name="message"
              onChange={setFields}
              required
            />
          </div>
          <div className="contact__form--input">
            <Button tag="button" text="Send" type="submit" />
          </div>
        </form>
        <div className="contact__info">
          <ul className="contact__info--list">
            {services.map((service, index) => {
              return (
                <li
                  key={index}
                  className="contact__info--list-item"
                  style={{
                    borderBottom: `4px solid hsl(${
                      Math.random() * 360
                    }, 100%, 50%)`,
                  }}
                >
                  <span className="contact__info--list-item-icon">
                    {service.icon}
                  </span>
                  {service.name}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      <Modal isShowing={isShowing} hide={toggle} />
    </section>
  );
};

export default Contact;
