import React from "react";
import {
  Contact,
  DarkModeToggle,
  Footer,
  Graphics,
  Hero,
  Navbar,
  Projects,
  SocialMedia,
} from "./components";
import analytics from "./helpers/analytics";

function App() {
  analytics();
  const onClientSide = typeof window !== "undefined";
  if (window.location.pathname !== "/" && onClientSide) {
    window.location.href = "/";
  }

  return (
    <div className="App">
      <Navbar />
      <DarkModeToggle />
      <SocialMedia />
      <div className="content">
        <Hero />
        <Projects />
        <Graphics />
        <Contact />
        <Footer />
      </div>
    </div>
  );
}

export default App;
