import React from "react";
import { Link } from "react-scroll";

const About = () => {
  return (
    <section className="about">
      {/* <h2 className="about__title">About Me</h2> */}
      <div className="about__bio">
        <div className="about__bio--backBorder">
          <div className="about__bio--info">
            <p>
              Ambitious <strong> Full-Stack Developer </strong>
              experienced in multiple software technologies, object-oriented
              programming, database designs, web application implementation and
              development, and agile methodologies. Possess a strong work ethic
              and a desire to learn new technologies and languages. Polyglot
              communicator, effective team player, and problem solver.
              <br />
              <br /> I make the web a better place by
              <strong>
                {" "}
                building unique user experinces and functional websites{" "}
              </strong>
              that are responsive and accessible to everyone. Let's{" "}
              <strong>
                <em>
                  <span>
                    <Link to="email" smooth duration={900} href="#email">
                      TALK WEB!
                    </Link>
                  </span>
                </em>
              </strong>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
