import React from "react";
import useDarkMode from "use-dark-mode";
import Card from "../../helpers/Card";
import professionalWork from "../../helpers/professionalWork.json";

const Projects = () => {
  const darkMode = useDarkMode();

  return (
    <>
      <section className="projects">
        <div
          style={{
            backgroundImage: `url(${
              darkMode.value ? "./stroke-dark.png" : "./stroke-light.png"
            })`,
            backgroundPosition: "left",
            backgroundRepeat: "no-repeat",
            position: "relative",
          }}
        >
          <h2 className="projects__title">Professional Work</h2>
        </div>

        <div className="projects__tabs">
          {professionalWork.map((project, i) => (
            <Card key={i} {...project} />
          ))}
        </div>
      </section>
    </>
  );
};
export default Projects;
