import React from "react";
import graphics from "../../helpers/graphics.json";
import PropTypes from "prop-types";
import useDarkMode from "use-dark-mode";
const Card = ({ image, description, className }) => {
  const [angle, setAngle] = React.useState(Math.random() * 90 - 45);
  const [xPos, setXPos] = React.useState(Math.random() * 40 - 20);
  const [yPos, setYPos] = React.useState(Math.random() * 40 - 20);
  const transform = `translate(${xPos}px, ${yPos}px) rotate(${angle}deg)`;

  return (
    <img
      style={{ transform }}
      src={image}
      className={className}
      alt={description}
      onMouseEnter={() => {
        setAngle(Math.random() * 90 - 45);
        setXPos(Math.random() * 40 - 20);
        setYPos(Math.random() * 40 - 20);
      }}
      title={description}
    />
  );
};

Card.prototype = {
  image: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  className: PropTypes.string,
};

const Graphics = () => {
  const [deck, setDeck] = React.useState(graphics);
  const [cardDrawn, setCardDrawn] = React.useState([]);
  const darkMode = useDarkMode();
  const drawCard = () => {
    const card = deck[Math.floor(Math.random() * deck.length)];

    setCardDrawn((currentCard) => [...currentCard, card]);
    setDeck((currentDeck) => currentDeck.filter((c) => c.id !== card.id));
  };

  const resetGame = () => {
    setDeck(graphics);
    setCardDrawn([]);
  };

  const cards = cardDrawn.map((card) => (
    <Card
      key={card.id}
      image={card.image}
      description={card.description}
      className="graphics__card"
    />
  ));

  return (
    <section className="graphics">
      <div
        className="graphics__header"
        style={{
          backgroundImage: `url(${
            darkMode.value ? "./stroke-dark.png" : "./stroke-light.png"
          })`,
          backgroundPosition: "left",
          backgroundRepeat: "no-repeat",
          position: "relative",
          minHeight: "130px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <h2 className="graphics__title">Graphics</h2>
      </div>

      <div className="graphics__buttonContainer">
        <div className="mas">
          <span>{deck.length === 0 ? "Reset" : "Draw"}</span>
        </div>
        <button
          type="button"
          className="graphics__button"
          onClick={deck.length === 0 ? resetGame : drawCard}
        >
          {deck.length === 0 ? "Reset" : "Draw"}
        </button>
      </div>

      {cardDrawn.length > 0 && <div className="graphics__deck">{cards}</div>}

      {cardDrawn.length === 0 && (
        <div className="graphics__deckEmpty">
          <div className="star-five"></div>
          <h2 className="graphics__titleEmpty">
            Use the button above to draw different art pieces
          </h2>
        </div>
      )}
    </section>
  );
};

export default Graphics;
